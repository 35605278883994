// Handle form submission if fetch is not applicable
var handleFormSubmission = function handleFormSubmission(postBackURL, punchoutResponseMsg, isDecodeNeeded, isSapOci) {
  var form = document.createElement('FORM');
  form.method = 'POST';
  form.enctype = 'application/x-www-form-urlencoded';
  form.style.display = 'none';
  form.action = postBackURL;

  if (isSapOci) {
    var inputVals = JSON.parse(punchoutResponseMsg);
    Object.keys(inputVals).forEach(function (key) {
      var input = document.createElement('INPUT');
      input.name = key;
      input.value = inputVals[key];
      form.appendChild(input);
    });
  } else {
    var input = document.createElement('INPUT');
    input.type = 'hidden';
    input.name = isDecodeNeeded ? 'cXML-urlencoded' : 'cXML-base64';
    input.value = isDecodeNeeded ? decodeURIComponent(Array.from(atob(punchoutResponseMsg)).map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join('')) : punchoutResponseMsg;
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
}; // Strip credentials from the postBackURL only if they are present


var stripCredentialsFromURL = function stripCredentialsFromURL(postBackURL) {
  var url = new URL(postBackURL);

  if (url.username || url.password) {
    url.username = '';
    url.password = '';
  }

  return url.toString();
}; // Helper function to validate punchoutData


var isValidPunchoutData = function isValidPunchoutData(data) {
  return data.header && Array.isArray(data.header) && data.header.length > 0 && data.header[0].username && data.header[0].password;
}; // Handle fetch request submission for oci customer


var handleFetchSubmission = function handleFetchSubmission(punchoutData, postBackURL) {
  var _punchoutData$header$ = punchoutData.header[0],
      username = _punchoutData$header$.username,
      password = _punchoutData$header$.password;
  var credentials = "".concat(username, ":").concat(password);
  var encodedCredentials = btoa(credentials);
  var authHeader = "Basic ".concat(encodedCredentials); // Strip credentials only if present in the postBackURL

  var sanitizedURL = stripCredentialsFromURL(postBackURL);
  var options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: authHeader
    },
    body: JSON.stringify(punchoutData)
  };
  fetch(sanitizedURL, options).then(function (response) {
    if (!response.ok) {
      throw new Error("Network response was not ok: ".concat(response.statusText));
    }

    return response.json();
  }).then(function () {
    // Redirect the user to the target URL after successful JSON submission
    window.location.href = sanitizedURL; // Uncomment to redirect
  })["catch"](function (error) {
    console.error('There was an error with the JSON request:', error);
  });
}; // Main function to handle data submission


var postB2bData = function postB2bData(postBackURL, punchoutResponseMsg, isDecodeNeeded, isSapOci) {
  // Try to parse the punchoutResponseMsg as JSON, if it fails, fall back to regular form submission flow
  var punchoutData;

  try {
    punchoutData = JSON.parse(punchoutResponseMsg);
  } catch (error) {
    handleFormSubmission(postBackURL, punchoutResponseMsg, isDecodeNeeded, isSapOci);
    return;
  } // If parsed as JSON, check if it contains the required header with username and password


  if (isValidPunchoutData(punchoutData)) {
    handleFetchSubmission(punchoutData, postBackURL);
  } else {
    // If JSON doesn't contain required data, fall back to form submission
    handleFormSubmission(postBackURL, punchoutResponseMsg, isDecodeNeeded, isSapOci);
  }
};

export default postB2bData;